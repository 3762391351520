var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"paragraph-history"},[_c('div',{staticClass:"w-100 d-flex justify-content-end mb-1"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"success"},on:{"click":_vm.generateExcel}},[_vm._v("Excel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(_vm._s(_vm.$t('Add')))])],1),(_vm.datas.active_history)?_c('b-card',[_c('b-table',{attrs:{"items":[_vm.datas],"fields":_vm.fields,"striped":"","hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.active_history.status.name)+" ")])]),_c('br'),(item.active_history.status_title)?_c('span',[_vm._v(" "+_vm._s(item.active_history.status_title)+" ")]):_vm._e()]}},{key:"cell(ministry_name)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('div',[(item.active_history.management)?_c('span',[_vm._v(" "+_vm._s(item.active_history.management_type.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.active_history.management.name)+", "),_c('br'),(item.active_history.management.user)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.active_history.management.user.lastname)+" "+_vm._s(item.active_history.management.user.firstname)+" ")])]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.active_history ? item.active_history.ministry_name : '')+" ")])]):_vm._e()]}},{key:"cell(deadline)",fn:function(ref){
var item = ref.item;
return [(item.active_history)?_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")((item.active_history ? item.active_history.deadline : '')))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)]):_vm._e()]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[(_vm.profile.role_id === 2)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"warning"},on:{"click":function($event){return _vm.handleEdit(data.item.active_history.id)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e(),(data.item.active_history.file_path)?_c('a',{attrs:{"target":"_blank","href":("" + (_vm.backendPath.backendPath) + (data.item.active_history.file_path.slice(1)))}},[_c('b-button',{attrs:{"size":"sm","variant":"info"}},[_c('b-icon',{attrs:{"icon":"download"}})],1)],1):_vm._e()],1)]}}],null,false,3238881984)})],1):_vm._e(),_c('b-card',[_c('div',{staticClass:"mb-2"},[_c('h3',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('message.History'))+" ")])])]),_c('b-table',{attrs:{"items":_vm.datas.histories,"fields":_vm.hisotryItems,"striped":"","hover":"","responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(ministry_name)",fn:function(ref){
var item = ref.item;
return [(item)?_c('div',[(item.management)?_c('span',[_vm._v(" "+_vm._s(item.management_type.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.management.name)+","),_c('br'),(item.management.user)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.management.user.lastname)+" "+_vm._s(item.management.user.firstname)+" ")])]):_vm._e()]):_c('span',[_c('strong',[_vm._v(" "+_vm._s(item ? item.ministry_name : '')+" ")])])]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.status.name)+" ")])]),_c('br'),(item.status_title)?_c('span',[_vm._v(" "+_vm._s(item.status_title)+" ")]):_vm._e()]}},{key:"cell(author)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.firstname)+" "+_vm._s(item.author.lastname)+" ")]}},{key:"cell(updated_date)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-content-center"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.updated_at))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [(item.file_path)?_c('a',{attrs:{"target":"_blank","href":("" + (_vm.backendPath.backendPath) + (item.file_path))}},[_c('b-button',{attrs:{"size":"sm","variant":"info"}},[_c('b-icon',{attrs:{"icon":"download"}})],1)],1):_vm._e()]}}])})],1),_c('b-modal',{ref:"modal",attrs:{"id":"modal-prevent-closing","title":_vm.modalTitle,"hide-footer":"","size":"lg","scrollable":""},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('HistoryForm',{attrs:{"id":_vm.id},on:{"saved":_vm.fetchList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }