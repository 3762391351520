<template>
<section class="paragraph-history">
  <div class="w-100 d-flex justify-content-end mb-1">
    <b-button variant="success" @click="generateExcel" class="mr-1">Excel</b-button>
    <b-button variant="primary" @click="handleAdd">{{$t('Add')}}</b-button>
  </div>
  <b-card v-if="datas.active_history">
    <b-table :items="[datas]" :fields="fields"
             striped
             hover
             responsive>
      <template #cell(id)="data">
        <span class="text-md text-primary"> {{ (data.index +1) }}</span>
      </template>
      <template #cell(status)="{item}">
        <span>
          <strong>
            {{item.active_history.status.name}}
          </strong>
        </span>
        <br>
        <span v-if="item.active_history.status_title">
        {{item.active_history.status_title}}
      </span>
      </template>
      <template #cell(ministry_name)="{item}">
      <div v-if="item.active_history">
        <span v-if="item.active_history.management">
        {{item.active_history.management_type.name}} <br> {{item.active_history.management.name}}, <br>
        <span v-if="item.active_history.management.user">
          <strong>
            {{item.active_history.management.user.lastname}} {{item.active_history.management.user.firstname}}
          </strong>
        </span>
      </span>
        <span v-else>
        {{item.active_history ? item.active_history.ministry_name : ''}}
      </span>
      </div>
      </template>
      <template #cell(deadline)="{item}">
        <span v-if="item.active_history" class="d-flex justify-content-center">
            <span>
              {{ (item.active_history ? item.active_history.deadline : '') | dateFormat }}
            </span>
            <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
          </span>
      </template>

      <template #cell(action)="data">
        <div class="d-flex">
          <b-button
              v-if="profile.role_id === 2"
              size="sm"
              variant="warning"
              class="mr-1"
              @click="handleEdit(data.item.active_history.id)"
          >
            <b-icon icon="pencil" />
          </b-button>
          <a v-if="data.item.active_history.file_path" target="_blank" :href="`${backendPath.backendPath}${data.item.active_history.file_path.slice(1)}`">
            <b-button size="sm" variant="info">
              <b-icon icon="download"></b-icon>
            </b-button>
          </a>
<!--          <b-button-->
<!--              class="ml-1"-->
<!--              size="sm"-->
<!--              variant="danger"-->
<!--              @click="handleDelete(data.item.id)"-->
<!--          >-->
<!--            <b-icon icon="trash" />-->
<!--          </b-button>-->
        </div>
      </template>
    </b-table>
  </b-card>

  <b-card>
    <div class="mb-2">
      <h3 ><strong>
        {{$t('message.History')}}
      </strong></h3>
    </div>
    <b-table :items="datas.histories" :fields="hisotryItems"
             striped
             hover
             responsive>
      <template #cell(id)="data">
        <span class="text-md text-primary"> {{ (data.index +1) }}</span>
      </template>
      <template #cell(ministry_name)="{item}">
        <div v-if="item">
        <span v-if="item.management">
        {{item.management_type.name}} <br> {{item.management.name}},<br>
        <span v-if="item.management.user">
          <strong>
            {{item.management.user.lastname}} {{item.management.user.firstname}}
          </strong>
        </span>
      </span>
          <span v-else>
        <strong>
          {{item ? item.ministry_name : ''}}
        </strong>
      </span>
        </div>
      </template>
      <template #cell(status)="{item}">
        <span>
          <strong>
            {{item.status.name}}
          </strong>
        </span>
        <br>
        <span v-if="item.status_title">
        {{item.status_title}}
      </span>
      </template>
      <template #cell(author)="{item}">
        {{item.author.firstname}} {{item.author.lastname}}
      </template>
      <template #cell(updated_date)="{item}">
        <span class="d-flex justify-content-center">
            <span>
              {{ item.updated_at | dateFormat }}
            </span>
            <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
          </span>
      </template>
      <template #cell(action)="{item}">
        <a v-if="item.file_path" target="_blank" :href="`${backendPath.backendPath}${item.file_path}`">
          <b-button size="sm" variant="info">
            <b-icon icon="download"></b-icon>
          </b-button>
        </a>
      </template>

    </b-table>
  </b-card>
  <b-modal
      id="modal-prevent-closing"
      ref="modal"
      v-model="modalVisible"
      :title="modalTitle"
      hide-footer
      size="lg"
      scrollable
  >
    <HistoryForm :id="id" @saved="fetchList" />
  </b-modal>
</section>
</template>

<script>
import { listTemplate } from '@/utils/mixins/listTemplate'
import { getParagraphHistory } from '@/api/documents'
import HistoryForm from '@/views/loginpages/moderator/documents/HistoryForm'
import { getUserData } from '@/libs/auth'
import backendPath from '../../../../libs/vue-axios.config'
// eslint-disable-next-line import/named
import { generateParagraphHistoryExcel} from "@/api/directories";
const actions = {
  get: getParagraphHistory,
}

export default {
  name: 'ParagraphHistory',
  mixins: [
    listTemplate,
  ],
  components:{
    HistoryForm,
  },
  computed: {
    profile() {
      return getUserData().userData;
    },
  },
  data() {
    return {
      actions,
      backendPath,
      filters: {
        document_id: this.$route.params.id,
        paragraph_id: this.$route.params.paragraphId,
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'paragraph',
          label: this.$t('Paragraph'),
        },
        {
          key: 'ministry_name',
          label: this.$t('Ministry_name'),
        },
        {
          key: 'deadline',
          label: this.$t('Deadline'),
        },
        {
          key: 'status',
          label: this.$t('Status_id'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
      hisotryItems: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'author',
          label: this.$t('Author'),
        },
        {
          key: 'ministry_name',
          label: this.$t('Ministry_name'),
        },
        {
          key: 'status',
          label: this.$t('Status_id'),
        },
        {
          key: 'updated_date',
          label: this.$t('Updated_date'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  methods: {
    generateExcel() {
      this.showLoading = true;
      generateParagraphHistoryExcel({document_id:this.$route.params.id, paragraph_id: this.$route.params.paragraphId}).then(({data}) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'paragraph.xlsx');
        document.body.appendChild(link);
        link.click()
        this.showLoading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
