<template>
  <div class="pb-2 pt-1">
    <div>
      <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
      <validation-observer ref="observer" class="w-100">
        <b-container>
          <b-row>
            <b-form-group
                v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                  v-model="isMno"
                  :aria-describedby="ariaDescribedby"
                  name="radio"
                  :value="true"
              >
                {{$t('Mno_center')}}
              </b-form-radio>
              <b-form-radio
                  v-model="isMno"
                  :aria-describedby="ariaDescribedby"
                  name="radio"
                  :value="false"
                  class="mt-1"
              >
                {{$t('Another_statement')}}
              </b-form-radio>
            </b-form-group>
          </b-row>
        </b-container>
        <div
            v-if="isMno"
            class="mt-2"
        >
<!--          <h5>{{$t('Management')}}</h5>-->
         <b-form-group :label="$t('ChooseManagementType')">
           <b-form-select
               v-model="form.management_type_id"
               :options="management_type"
               text-field="name"
               value-field="id"
               name="management_type"
               @input="getManagementCategory"
           />
         </b-form-group>
          <b-form-group :label="$t('ChooseRank')">
            <b-form-select
                v-model="form.management_id"
                :options="management_category"
                text-field="name"
                value-field="id"
                name="management_category"
                @input="getManagementUser"
            />
          </b-form-group>
        </div>
        <div v-else>
          <b-form-group
              :label="$t('MinistryName')"
              label-for="name-input"
              invalid-feedback="Name is required"
          >
            <b-form-input
                id="name-input"
                name="ministry_name"
                v-model="form.ministry_name"
                required
                size="lg"
            />
          </b-form-group>
        </div>

        <b-row class="mt-3">
          <b-col md="12">
            <h6>{{$t('Action')}}</h6>
            <b-form-group
                v-slot="{ ariaDescribedby }"
            >
              <b-form-radio
                  v-for="(val, index) in statuses"
                  :key="index"
                  v-model="form.status_id"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="val.id"
                  class="mt-1"
              >
                {{val.name}}
              </b-form-radio>

            </b-form-group>
            <div class="mt-2">
              <b-row>
                <b-col>
                  <b-form-group :label="$t('Deadline')">
<!--                    <b-form-datepicker-->
<!--                        v-model="form.deadline"-->
<!--                        name="notification_date"-->
<!--                        locale="en"-->
<!--                    />-->
                    <input type="date" class="form-control" v-model="form.deadline" name="notification_date">
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <div class="w-100">
          <b-form-group :label="$t('message.StatusTitleUz')">
            <b-textarea v-model="form.status_title_uz"></b-textarea>
          </b-form-group>
        </div>
        <div class="w-100">
          <b-form-group :label="$t('message.StatusTitleRu')">
            <b-textarea v-model="form.status_title_ru"></b-textarea>
          </b-form-group>
        </div>
        <div class="w-100">
          <b-form-group :label="$t('message.StatusTitleEn')">
            <b-textarea v-model="form.status_title_en"></b-textarea>
          </b-form-group>
        </div>
        <div class="w-100">
          <b-form-group>
            <input type="file" @change="getFile">
          </b-form-group>
        </div>
        <div class="mt-2">
          <b-container fluid>
            <div class="text-right mt-3">
              <b-button
                  variant="danger"
                  class="mr-2"
                  @click="modalActive = false"
              >
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                  variant="success"
                  @click="saveSubmit"
              >
                {{$t('Add')}}
              </b-button>
            </div>
          </b-container>
        </div>

      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  storeDocumentHistory, updateDocumentHistory, getDocumentHistory,
} from '@/api/documents'
import { getManagement, getManagementTypes, getStatuses } from '@/api/directories'

import { formTemplate } from '@/utils/mixins/formTemplate'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    paragraph_id: get('paragraph_id', null),
    status_id: get('status_id', null),
    executer_mno: get('executer_mno', null),
    management_type_id: get('management_type_id', null),
    management_id: get('management_id', null),
    ministry_name: get('ministry_name', null),
    deadline: get('deadline', null),
    fio: get('fio', null),
    status_title_uz: get('status_title_uz', null),
    status_title_ru: get('status_title_ru', null),
    status_title_en: get('status_title_en', null),
    file: get('file', null)
  }
}

const actions = {
  add: storeDocumentHistory,
  update: updateDocumentHistory,
  show: getDocumentHistory,
}

export default {
  name: 'HistoryForm',
  mixins: [formTemplate],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      actions,
      form: initForm(),
      management_type: [],
      management_category: [],
      isMno: true,
      statuses: [],
    }
  },
  created() {
    getManagementTypes().then(res => {
      this.management_type = res.data.data
    });
    getStatuses().then(res => {
      this.statuses = res.data.data;
    })
  },
  methods: {
    reformatData() {
      this.form.document_id = this.$route.params.id;
      this.form.executer_mno = this.isMno;
      if (this.$route.params.paragraphId) {
        this.form.paragraph_id = this.$route.params.paragraphId;
      }
      const form = this.prepareFormData(this.form)
      return form
    },
    setForm(data) {
      this.form = initForm(data);
    },
    fetchManagement() {
      getManagement().then(res => {
        this.managements = res.data.data;
      })
    },
    getManagementCategory() {
      getManagement({ type_id: this.form.management_type_id, pageSize: 30 }).then(res => {
        this.management_category = res.data.data.data
      })
    },
    getManagementUser() {
      const { user } = this.this.paragraph.management_id.user
      this.paragraph.fio = `${user.lastname} ${user.firstname}`
      this.paragraph.executer_mno = user.id
    },
    getFile(e) {
      this.form.file = e.target.files[0]
    },
  },
}
</script>

<style scoped>

</style>
